import React from "react";
import { Flex, Heading, Button, Spinner } from "theme-ui";
import BaseRegistarImplementationMetadata from "src/abis/BaseRegistrarImplementation.json";
import { BaseRegistrarImplementation } from "src/generated/BaseRegistrarImplementation";
import PublicResolverMetadata from "src/abis/PublicResolver.json";
import { PublicResolver } from "src/generated/PublicResolver";
import NomRegistrarControllerMetadata from "src/abis/NomRegistrarController.json";
import { NomRegistrarController } from "src/generated/NomRegistrarController";
import NomstronautMetadata from "src/abis/Nomstronaut.json";
import { Nomstronaut } from "src/generated/Nomstronaut";
import MulticallAbi from "src/abis/Multicall.json";
import { useAsyncState } from "src/hooks/useAsyncState";
import { AbiItem } from "web3-utils";
import { BlockText } from "src/components/BlockText";
import { CSVLink } from "react-csv";
import Web3 from "web3";
import localforage from "localforage";
var namehash = require("eth-ens-namehash");

const CREATION_BLOCK = 10910371;
const BUCKET_SIZE = 1000;

export const getPastEvents = async (
  contract: any,
  eventName: string,
  fromBlock: number,
  toBlock: number,
  filter?: any
) => {
  console.log("Fetching events");
  let startBlock = fromBlock || 0;
  const bucketSize = 50_000;
  const promises = [];
  const key = String(contract.options.address) + eventName;
  const cachedEvents = await localforage.getItem<string>(key);
  let events = cachedEvents ? JSON.parse(cachedEvents) : [];

  try {
    startBlock = events[events.length - 1].blockNumber + 1;
  } catch (e) {
    console.log("Failed to assign startBlock");
  }

  for (
    let i = Math.floor(startBlock / bucketSize);
    i < Math.ceil(toBlock / bucketSize);
    i++
  ) {
    promises.push(
      contract.getPastEvents(eventName, {
        fromBlock: Math.max(i * bucketSize, startBlock),
        toBlock: Math.min((i + 1) * bucketSize, toBlock) - 1,
        filter,
      })
    );
  }
  const newItems = await Promise.all(promises).then((es) => es.flat());
  const output =
    events.length > 0 ? (events as any[]).concat(newItems) : newItems;

  console.info(`Fetched ${output.length} ${eventName} events`);
  await localforage.setItem(key, JSON.stringify(output));
  return output;
};

const getResolutions = async (
  multicall: any,
  reserveEvents: any,
  resolver: any
) => {
  console.log("Fetching resolutions");
  const total = reserveEvents.length;
  const promises = [];

  const key = String(resolver.options.address);
  const cachedEvents = await localforage.getItem<string>(key);
  let events = cachedEvents ? JSON.parse(cachedEvents) : [];
  let startBlock = 0;

  try {
    startBlock = events[events.length - 1].blockNumber + 1;
  } catch (e) {
    console.log("Failed to set startBlock");
  }

  let i = startBlock;
  while (i < total) {
    promises.push(
      multicall.methods
        .aggregate(
          reserveEvents
            .slice(i, i + BUCKET_SIZE)
            .map((e: any) => [
              resolver.options.address,
              resolver.methods["addr(bytes32)"](
                namehash.hash(`${e.returnValues.name}.nom`)
              ).encodeABI(),
            ])
        )
        .call()
        .then((cr: any) =>
          cr.returnData.map((address: string) =>
            address.replace("0x000000000000000000000000", "0x")
          )
        )
    );
    i += BUCKET_SIZE;
  }
  const newItems = await Promise.all(promises).then((es) => es.flat());
  const resolutions =
    events.length > 0 ? (events as any[]).concat(newItems) : newItems;

  await localforage.setItem(key, JSON.stringify(resolutions));
  return resolutions;
};

const getOwners = async (multicall: any, base: any, reserveEvents: any) => {
  console.log("Fetching owners");
  const total = reserveEvents.length;
  const promises = [];

  const key = String(base.options.address) + "Owners";
  const cachedEvents = await localforage.getItem<string>(key);
  let events = cachedEvents ? JSON.parse(cachedEvents) : [];
  let startBlock = 0;

  try {
    startBlock = events[events.length - 1].blockNumber + 1;
  } catch (e) {
    console.log("Failed to assign startBlock");
  }

  let i = startBlock;
  while (i < total) {
    promises.push(
      multicall.methods
        .aggregate(
          reserveEvents
            .slice(i, i + BUCKET_SIZE)
            .map((e: any) => [
              base.options.address,
              base.methods.ownerOf(e.returnValues.label).encodeABI(),
            ])
        )
        .call()
        .then((cr: any) =>
          cr.returnData.map((address: string) =>
            address.replace("0x000000000000000000000000", "0x")
          )
        )
    );
    i += BUCKET_SIZE;
  }
  const newItems = await Promise.all(promises).then((es) => es.flat());
  const owners =
    events.length > 0 ? (events as any[]).concat(newItems) : newItems;
  //console.log(owners)
  await localforage.setItem(key, JSON.stringify(owners));
  return owners;
};

const getExpirations = async (
  multicall: any,
  base: any,
  reserveEvents: any
) => {
  console.log("Fetching expirations");
  const total = reserveEvents.length;
  const promises = [];

  const key = String(base.options.address) + "Expirations";
  const cachedEvents = await localforage.getItem<string>(key);
  let events = cachedEvents ? JSON.parse(cachedEvents) : [];
  let startBlock = 0;

  try {
    startBlock = events[events.length - 1].blockNumber + 1;
  } catch (e) {
    console.log("Failed to assign startBlock");
  }

  let i = startBlock;
  while (i < total) {
    promises.push(
      multicall.methods
        .aggregate(
          reserveEvents
            .slice(i, i + BUCKET_SIZE)
            .map((e: any) => [
              base.options.address,
              base.methods.nameExpires(e.returnValues.label).encodeABI(),
            ])
        )
        .call()
        .then((cr: any) =>
          cr.returnData.map((expiration: string) => parseInt(expiration, 16))
        )
    );
    i += BUCKET_SIZE;
  }
  const newItems = await Promise.all(promises).then((es) => es.flat());
  const expirations =
    events.length > 0 ? (events as any[]).concat(newItems) : newItems;

  await localforage.setItem(key, JSON.stringify(expirations));
  return expirations;
};

const getNFTOwners = async (
  multicall: any,
  base: any,
  reserveEvents: any,
  nomstronaut: any
) => {
  console.log("Fetching NFT owners");
  const total = reserveEvents.length;
  const promises = [];

  let i = 0;
  while (i < total) {
    promises.push(
      multicall.methods
        .aggregate(
          reserveEvents
            .slice(i, i + BUCKET_SIZE)
            .map((e: any) => [
              nomstronaut.options.address,
              nomstronaut.methods.balanceOf(e.returnValues.owner).encodeABI(),
            ])
        )
        .call()
        .then((cr: any) =>
          cr.returnData.map((address: string) =>
            address.replace("0x000000000000000000000000", "0x")
          )
        )
    );
    i += BUCKET_SIZE;
  }
  const result = await Promise.all(promises).then((es) => es.flat());
  const nftCount = result.map((e) => parseInt(e.replace(/\D/g, "")));
  return nftCount;
};

export const Stats: React.FC = () => {
  const web3 = React.useMemo(() => new Web3("https://forno.celo.org"), []);

  const call = React.useCallback(async () => {
    const base = new web3.eth.Contract(
      BaseRegistarImplementationMetadata.abi as AbiItem[],
      "0xdf204de57532242700D988422996e9cED7Aba4Cb"
    ) as unknown as BaseRegistrarImplementation;

    const nom = new web3.eth.Contract(
      NomRegistrarControllerMetadata.abi as AbiItem[],
      "0x046D19c5E5E8938D54FB02DCC396ACf7F275490A"
    ) as unknown as NomRegistrarController;

    const resolver = new web3.eth.Contract(
      PublicResolverMetadata.abi as AbiItem[],
      "0x4030B393bbd64142a8a69E904A0bf15f87993d9A"
    ) as unknown as PublicResolver;

    const nomstronaut = new web3.eth.Contract(
      NomstronautMetadata as AbiItem[],
      "0x8237f38694211F25b4c872F147F027044466Fa80"
    ) as unknown as Nomstronaut;

    const multicall = new web3.eth.Contract(
      MulticallAbi as AbiItem[],
      "0xC119574D5Fb333F5AC018658D4d8b5035E16bf39"
    );

    const latestBlock = await web3.eth.getBlockNumber();

    console.log("Fetching registration events");
    const pastEvents = await getPastEvents(
      nom,
      "NameRegistered",
      CREATION_BLOCK,
      latestBlock
    );

    console.log("Filtering for active registrations");
    const reserveEvents = pastEvents.filter(
      (e) => e.returnValues.expires > Math.round(new Date().getTime() / 1000)
    );

    const names = [];
    const labels = [];
    let totalReserved = 0;
    const uniqueNames: Record<string, boolean> = {};
    let numUniqueUsers = 0;
    const uniqueUsers: Record<string, boolean> = {};
    for (const re of reserveEvents) {
      names.push(re.returnValues.name);
      labels.push(re.returnValues.label);
      if (!uniqueNames[re.returnValues.name]) {
        totalReserved += 1;
        uniqueNames[re.returnValues.name] = true;
      }
      if (!uniqueUsers[re.returnValues.owner]) {
        numUniqueUsers += 1;
        uniqueUsers[re.returnValues.owner] = true;
      }
    }

    const owners = await getOwners(multicall, base, reserveEvents);

    const expirations = await getExpirations(multicall, base, reserveEvents);

    const resolutions = await getResolutions(
      multicall,
      reserveEvents,
      resolver
    );

    const nfts = await getNFTOwners(
      multicall,
      base,
      reserveEvents,
      nomstronaut
    );

    const csvData = [
      ["name", "owner", "expiration", "resolution", "ID", "nomstronauts owned"],
    ];
    for (let i = 0; i < owners.length; i++) {
      let expirationDate = new Date(expirations[i] * 1000);

      csvData.push([
        names[i],
        owners[i],
        expirationDate.toString(),
        resolutions[i],
        labels[i],
        nfts[i],
      ]);
    }

    console.log("Finished");
    return { totalReserved, numUniqueUsers, csvData };
  }, [web3]);

  const [stats] = useAsyncState(null, call);

  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: ["33%", "10%"],
      }}
    >
      <Heading as="h2">Total Noms reserved</Heading>
      {stats?.totalReserved ? (
        <BlockText variant="primary" mb={4}>
          {stats?.totalReserved}
        </BlockText>
      ) : (
        <Spinner />
      )}
      <Heading as="h2">Total unique users</Heading>
      {stats?.numUniqueUsers ? (
        <BlockText variant="primary" mb={4}>
          {stats?.numUniqueUsers}
        </BlockText>
      ) : (
        <Spinner />
      )}
      <br />
      {stats?.csvData ? (
        <CSVLink
          data={stats?.csvData}
          filename={"snapshot.csv"}
          onClick={() => {
            console.log("Snapshot Downloaded");
          }}
        >
          <Button>Take a snapshot</Button>
        </CSVLink>
      ) : (
        <Spinner />
      )}
    </Flex>
  );
};
